const portfolioData = [
  {
    id: 1,
    title: "Fluxx Mobile Apps - Simplify Your Finances",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740496818/thtmi24lkwi1han5xeqq.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740496818/thtmi24lkwi1han5xeqq.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740496920/okqsxyqdylgbxkq5ofzx.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740496987/pfv2d7vscfdjr5fsn1jd.png",
      },
    ],
    desc: "Fluxx is the ultimate expense and tax management app designed to streamline your financial journey. Effortlessly track your expenses, stay organized throughout the year. With intuitive features, Fluxx simplifies tax preparation by generating comprehensive reports and summaries, ensuring you're ready for tax time. Capture receipts, manage deductions, and gain insights into your spending patterns with ease.",
  },
  {
    id: 2,
    title: "SWOOPE - Largest Swiss marketplace for materials",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497618/hc9ijqarpttljlyrfkzj.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497618/hc9ijqarpttljlyrfkzj.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497401/awdocrasjpkwhah7ohij.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497472/xfciaaica8ainmmrpsob.png",
      },
      
    ],
    desc: "This platform simplifies the process of buying and selling equipment by connecting buyers and sellers directly. Buyers can easily search for equipment using filters like type, location, and price, while sellers can create free listings with detailed descriptions and photos. The platform facilitates direct communication between both parties, allowing them to schedule transactions, arrange deliveries, and complete payments securely. It’s a user-friendly, efficient, and reliable marketplace designed to meet the needs of individuals and businesses alike.",
  },
  {
    id: 3,
    title: "SEHATTI - The Smart HR Management System",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497797/ni9n6pmbyakuxl1by555.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497797/ni9n6pmbyakuxl1by555.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497879/wtpg5tmp2m4tgh0xobod.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740497940/zt2gco9nzisonhwta62r.png"
      }
      
    ],
    desc: "The Smart HR Management System for Employee Performance Evaluation is designed to help organizations monitor, analyze, and enhance employee performance, wellbeing, and collaboration. With dedicated dashboards for Corporate Admins (HR), Employees, and System Admins, the platform simplifies employee onboarding through bulk uploads, allowing HR to create multiple employee accounts via CSV files. Employees receive personalized email notifications for account setup, ensuring a seamless onboarding process. The Employee Dashboard offers modules like the Workout Hub, Eating Healthy Hub, and the innovative Smart QA Notification System, where employees answer quick daily surveys using Yes/No responses and Emoji ratings. This system is divided into three key divisions—Wellbeing, Productivity, and Team Building—capturing daily insights on physical health, stress levels, task completion, and team dynamics. The platform calculates individual and departmental performance scores, visualizing trends through heatmaps, graphs, and traffic light indicators (Green, Yellow, Red) to help HR identify areas that require attention. Additionally, HR/Admin can activate, suspend, and manage employee accounts, both individually and in bulk. With its comprehensive analytics and real-time insights, this system empowers organizations to proactively address employee challenges, foster engagement, and cultivate a healthier, more productive workplace.",
  },
  {
    id: 4,
    title: "REKOUL - The Switzerland-Based Food Ordering System",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740498839/g6n8jg0jhblwocokpeis.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740498839/g6n8jg0jhblwocokpeis.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499707/pprjse378mbnhiss4awi.png ",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499776/ej79lgnnniwxorfxwykj.png",
      },
      
    ],
    desc: "The Switzerland-Based Food Ordering System is a modern platform designed to simplify food ordering and delivery, allowing users to order meals from their favorite restaurants and send food as customizable buckets. With a user-friendly interface, the system offers personalized profiles where users can manage their information, including name, profile picture, email, and order history. The Order History section provides a detailed overview of past orders, including restaurant names, order dates, items ordered, and amounts paid. The platform supports multiple Payment Methods such as credit/debit cards, PayPal, and Apple Pay for seamless transactions. Users can customize their experience through comprehensive Settings, including Notification Settings for offers, order confirmations, and reminders, as well as Location Settings to find nearby restaurants using manual selection or automatic detection. The platform supports multiple languages like English, French, German, and Spanish, ensuring accessibility for a diverse user base. Account Settings enable users to update personal details, manage passwords, and delete accounts if needed. Additionally, Privacy Settings allow users to control data sharing and targeted advertising, with easy access to the app’s privacy policy and terms of use.For additional support, the Help and Support section provides FAQs, customer service contact information, and helpful resources. The About the App section offers insights into the app’s version, release date, and developer contact information. Overall, this food ordering system is designed to enhance user convenience, offering a reliable, personalized, and secure experience for food delivery and bucket-sharing services across Switzerland.",
  },
  {
    id: 5,
    title: "Stock Management Solution for Netherlands Distributed Company",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499188/gmz24uoqakcdevjykrtr.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499188/gmz24uoqakcdevjykrtr.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499374/vaa8yhjdocqhunvhvgtm.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499541/im3srnhfnwnac8fibyxf.png",
      },
      
    ],
    desc: "The Stock Management Solution for Netherlands Distributed Companies is a comprehensive platform designed to streamline inventory management and enhance operational efficiency for companies across the Netherlands. With an intuitive dashboard, users can manage key business elements such as Users, Companies, Products, Categories, and Events. The system allows administrators to create and manage company profiles by inputting essential details like name, email, phone number, abbreviation, and website. Additionally, the platform supports multi-language functionality, enabling businesses to operate seamlessly in diverse linguistic environments. By providing real-time stock insights and efficient company management, this solution empowers businesses to optimize their supply chain, reduce stock discrepancies, and improve overall productivity.",
  },
  {
    id: 6,
    title: "German LAW AI - Intelligent legal assistant",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499926/wdl6zeljkvbu98ntyomy.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499926/wdl6zeljkvbu98ntyomy.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740499977/dwdpggtd0uv5f3sizzdx.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740500088/yohqfvlts7qlpricnfev.png",
      },
      
    ],
    desc: "German Law AI is an intelligent legal assistant designed to help law students and lawyers analyze case files and identify relevant legal acts. Users can upload their case files, and the AI swiftly processes the information to suggest applicable laws and regulations. The platform also allows users to take notes for each case, ensuring organized and comprehensive case management. Additionally, users can download the analysis and results as a PDF for easy reference and documentation. With its advanced AI capabilities, German Law AI streamlines legal research, saving time and enhancing accuracy in legal practice and studies.",
  },
  {
    id: 7,
    title: "OneDNA - ERP Solution",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740500486/qjxx7lywgdpbksfon2ak.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740500486/qjxx7lywgdpbksfon2ak.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740500535/gv6dfjftde7klhp40esc.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740500581/zcquvf1tv7so69c1j66r.png",
      },
      
    ],
    desc: "All-in-One Business Management Tool. A comprehensive solution for managing email campaigns, social media, CRM, and analytics from a single platform. Quick setup in just one hour, allowing immediate optimization of your business operations.",
  },
  {
    id: 8,
    title: "Smart Audience Building - A Comprehensive Lead Solution",
    thumb: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740501017/lz103vcz41i7xdwdq4fm.png",
    image: [
      {
        id: 1,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740501017/lz103vcz41i7xdwdq4fm.png",
      },
      {
        id: 2,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740501112/dxczvfweaycnw373a3ui.png",
      },
      {
        id: 3,
        img: "https://res.cloudinary.com/dydfwvvro/image/upload/v1740501191/npnreoe1cwluduph8hwr.png",
      },
      
    ],
    desc: "Smart Audience Building - A Comprehensive Lead Solution is an advanced platform designed to help businesses identify, target, and engage their ideal customers. By leveraging data-driven insights and AI-powered analytics, the solution streamlines lead generation, audience segmentation, and personalized outreach. It enables businesses to build high-quality lead lists, optimize marketing campaigns, and improve conversion rates, ensuring efficient and effective customer acquisition. With intuitive tools and real-time tracking, Smart Audience Building empowers businesses to connect with the right audience, maximize engagement, and drive growth.",
  },
];

export default portfolioData;
