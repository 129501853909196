/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import flowImage from "../../../../Assets/Images/ai/aiBanner.png";
import video from "../../../../Assets/videos/ai.mp4";
import BannerElement from "../../../../Components/Banner/BannerElement/BannerElement";
import Container from "../../../../Components/Container/Container";
import FrameWorkCss from "../../../../Components/FrameWork/FrameWork.module.css";
import FrameWorkList from "../../../../Components/FrameWork/FrameWorkList/FrameWorkList";
import Heading from "../../../../Components/Heading/Heading";
import ServiceBody from "../../../../Components/ServiceBox/ServiceBody";
import ServiceBox from "../../../../Components/ServiceBox/ServiceBox";
import useWindowWidth from "../../../../Utils/WindowCaptcher";
import { pageData } from "../../ServiceData/pageData";

import asm from "../../../../Assets/Images/ai/amazonSageMacker.jpeg";
import aws from "../../../../Assets/Images/ai/aws.jpg";
import azure from "../../../../Assets/Images/ai/azure.png";
import chatGpt from "../../../../Assets/Images/ai/chatGPT.png";
import gimini from "../../../../Assets/Images/ai/gimini.webp";
import openCV from "../../../../Assets/Images/ai/openCV.png";
import ScrollToTop from "../../../../Components/ScrollToTop/ScrollToTop";
import Accordion from "../../../../Components/ServiceSection/Services/BannerServiceBox/ServiceBoxMobile";

const AI = () => {
  const linkName = useParams();
  const data = pageData.filter((data) => data.page === "ai")[0];
  const px645 = useWindowWidth(645);
  const px1280 = useWindowWidth(1280);
  const px1024 = useWindowWidth(1024);
  const width1280 = useWindowWidth(1280);
  const [active, setActive] = useState(data.service[0].title);

  const [bodyData, setBodyData] = useState({
    bodyImg: data.service[0].image,
    desc: data.service[0].desc,
    link: "/services/pos",
    title: data.service[0].title,
    img: "",
  });

  return (
    <div className="service_single_page">
      <ScrollToTop />
      <BannerElement video={video}>
        <Container>
          <div className="text-center">
            <div className="text-center text-[35px] font-bold text-white">
              {data?.banner?.title}
            </div>
            <p className="w-[50%] mx-auto text-white text-center text-[15px]">
              {data?.banner.subtitle}
            </p>
          </div>
        </Container>
      </BannerElement>

      <div
        style={{
          backgroundImage: ``,
          backgroundRepeat: "repeat",
          backgroundSize: "70%",
          backgroundAttachment: "fixed",
          backgroundColor: "#000",
        }}
        className="py-24"
      >
        <Container>
          {width1280 &&
            data.service.map((item, index) => {
              return (
                <div key={index}>
                  <Accordion item={item} noBtn />
                </div>
              );
            })}

          {!width1280 && (
            <>
              <ServiceBody title={active} bodyData={bodyData} />

              <div className="flex justify-center items-end -mt-12">
                {data.service.map((item, index) => (
                  <div className="" key={index}>
                    <ServiceBox
                      active={active}
                      setActive={setActive}
                      title={item.title}
                      icon={item.icon}
                      bodyImg={item.image}
                      desc={item.desc}
                      link={item.link}
                      setBodyData={setBodyData}
                      isLast={index === data.service.length - 1}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </Container>
      </div>

      <div className="bg-black py-24">
        <Container>
          <div className="flex flex-col justify-center items-center pb-5">
            <Heading first="Creativity Led, " middle="User Focused" />
            <p className="text-white w-[50%] text-center">
              Creativity-Led, User-Focused AI: Harnessing innovative solutions
              tailored to user needs and enhancing experiences.
            </p>
          </div>

          <div className="flex justify-center items-center">
            <img src={flowImage} alt="" />
          </div>
        </Container>
      </div>

      <div
        style={{
          backgroundImage:
            "url(https://i.ibb.co/5KDSC4j/White-Dots-D4-ib2-Mf.png)",
          backgroundRepeat: "repeat",
          backgroundSize: "70%",
          backgroundAttachment: "fixed",
          backgroundColor: "#000",
        }}
        className="py-24"
      >
        <div className={FrameWorkCss.frame_work}>
          <Container>
            <div className="flex flex-col justify-center items-center pb-5">
              <Heading first="Modern, " middle="AI Tools" />
              <p className="text-white w-[50%] text-center">
                {/* Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Quisquam, suscipit? */}
              </p>
            </div>

            <ul>
              {/* First Row  */}
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList logo={chatGpt} text="Chat GPT" border="topLeft" />
              <FrameWorkList logo={gimini} text="Gemini" border="topLeft" />
              <FrameWorkList logo={aws} text="AWS" border="topLeft" />
              <FrameWorkList logo={asm} text="ASM" border="topLeft" />
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList border="bottomLeft" />
              <FrameWorkList logo={openCV} text="Open CV" border="topLeft" />
              <FrameWorkList logo={azure} text="Azure" border="topLeft" />

              {/* Secound Row */}
              {/* <FrameWorkList border="bottomRight" /> */}
            </ul>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AI;
