import React from 'react';
import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';
import image from "../../../Assets/Images/product-design.svg";
import image2 from '../../../Assets/Images/service-image-1.svg';
import image3 from '../../../Assets/Images/service-image-3.svg';
import image4 from '../../../Assets/Images/service-image-4.svg';
import image5 from '../../../Assets/Images/service-image-5.svg';
import image6 from "../../../Assets/Images/service-image-6.svg";
import Container from '../../Container/Container';
import HeaderCss from '../Header.module.css';


const MenuList = () => (
  <ul>
    <li>
      <Link to="/">HOME</Link>
    </li>
    <li>
      <Link to="/about-us">ABOUT US</Link>
    </li>

    <li className="">
      <button className="flex items-center gap-1">
        SOLUTIONS <ChevronDown size={20} />
      </button>
      <div
        className={`w-[100%] bg-[#000000a9] py-5 absolute left-0 top-[20px] opacity-0 invisible border-t border-t-[#ffffff27]  ${HeaderCss.serviceHover}`}
      >
        <Container>
          <div className="grid grid-cols-6 gap-8">
            <Box
              title="ERP "
              subTitle="Solutions"
              img={image}
              desc="Transform your Core Business Processes with Pixfar Our All-in-One ERP Solution"
              link="/service/erp"
            />
            <Box
              title="Learning Management system"
              img={image2}
              desc="Streamline Your Sales Operations with Efficient POS Solutions"
              link="/service/lms"
            />
            <Box
              title="Data"
              subTitle="Engineering"
              img={image3}
              desc="Utilize Data for Strategic Decisions"
              link="/service/data-engineering"
            />
            <Box
              title="Remote Desktop"
              subTitle="Solution"
              img={image4}
              desc="Enhance Connectivity and Efficiency with Remote Desktop Solutions"
              link="/service/remote-desktop"
            />
            <Box
              title="Artificial Intelligence"
              subTitle=""
              img={image5}
              desc="Generative AI and ML to Lead Technological Innovation."
              link="/service/ai"
            />
            <Box
              title="Software"
              subTitle="Consulting"
              img={image6}
              desc="Elevate Your Business with Expert Software Consulting Services"
              link="/service/web-development"
            />
          </div>
        </Container>
      </div>
    </li>
  </ul>
);

export default MenuList;


const Box = ({ title, subTitle, desc, link ,img}) => {
  return (
    <Link className="bg-[#202020] p-6" to={link}>
             <img src={img ? img : image} alt="" className="h-[30px] w-[30px] " />

 {/* <ServiceBox isImage /> */}
 
      <h1
        className="text-left text-xl leading-6 text-white font-medium mb-4 mt-3"
        // style={{ lineHeight: 1 }}
      >
        {title} <br /> {subTitle}
      </h1>
      <p
        className="text-white text-sm text-left font-normal leading-5"
        // style={{ lineHeight: 1 }}
      >
        {desc || "N/A"}
      </p>
    </Link>
  );
};