/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { X } from 'react-feather';
import Button from '../Button/Button';
import Input from '../FormElement/Input/Input';
import Lable from '../FormElement/Lable/Lable';
import Textarea from '../FormElement/TextArea/Textarea';
import offCanvasCSS from './OffCanvas.module.css';

const OffCanvas = () => (
    <div className={`${offCanvasCSS.custom_offcanvas}`}>
        <input id="offcanvas" type="checkbox" className="hidden" />
        <div className={` ${offCanvasCSS.offcanvas_body}`}>
            <div className={`flex gap-3 border-b pb-2 items-center ${offCanvasCSS.header}`}>
                <label htmlFor="offcanvas" className="cursor-pointer border rounded-full p-1">
                    <X color="#008eff" />
                </label>
                <h1>Book a Meeting</h1>
            </div>
            <div className={`mt-5 ${offCanvasCSS.form_body}`}>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita saepe omnis
                    eum temporibus explicabo corporis assumenda amet reiciendis, esse ut!
                </p>
                <form action="#" className="mt-5">
                    <div className="mb-3">
                        <Lable title="Name :" id="quitname" />
                        <Input placeholder="Type Your Name" id="quitname" />
                    </div>
                    <div className="mb-3">
                        <Lable title="Email :" id="quitemail" />
                        <Input placeholder="Type Your Email" id="quitemail" />
                    </div>
                    <div className="mb-3">
                        <Lable title="Phone :" id="quitphone" />
                        <Input placeholder="Type Your Phone Number" id="quitphone" />
                    </div>
                    <div className="mb-3">
                        <Lable title="Country :" id="quitcountry" />
                        <Input placeholder="Type Your Country Name" id="quitcountry" />
                    </div>
                    <div className="mb-3">
                        <Lable title="Subject :" id="quitsubject" />
                        <Input placeholder="Type Subject" id="quitsubject" />
                    </div>
                    <div className="mb-3">
                        <Lable title="Message :" id="quitmessage" />
                        <Textarea placeholder="Type Message" id="quitmessage" row="2" />
                    </div>
                    <Button type="submit" text="Submit" className="w-full" />
                </form>
            </div>
        </div>
    </div>
);

export default OffCanvas;
