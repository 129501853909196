import React from 'react';
import logoCss from '../FrameWork.module.css';

const FrameWorkList = ({ logo, text, border }) => (
  <li
    className="text-center"
    style={
      (border === "topLeft" && {
        borderTop: "0",
        borderLeft: "0",
      }) ||
      (border === "topRight" && {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
      }) ||
      (border === "topBottom" && {
        borderTop: "0",
        borderLeft: "0",
        borderBottom: "0",
      }) ||
      (border === "bottomLeft" && {
        borderLeft: "0",
        borderTop: "0",
        borderBottom: "0",
        backgroundColor: "transparent",
      }) ||
      (border === "bottomRight" && {
        borderRight: "0",
        borderTop: "0",
        borderBottom: "0",
        borderLeft: "0",
        backgroundColor: "transparent",
      })
    }
  >
    {logo && (
      <div className={logoCss.slider_logo}>
        <img src={logo} alt="" />
        <h2 className="text-white">{text}</h2>
      </div>
    )}
  </li>
);

export default FrameWorkList;
