/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { Mail, Phone } from "react-feather";
import { MdPlace } from "react-icons/md";
import useWindowWidth from "../../Utils/WindowCaptcher";
import Container from "../Container/Container";
import SocialIcon from "../SocialIcon/SocialIcon";
import Title from "../Title/Title";
import formCss from "./contactForm.module.css";

const ContactForm = () => {
  // const navigate = useNavigate();
  const px1024 = useWindowWidth(1024);
  const px768 = useWindowWidth(768);
  const px500 = useWindowWidth(500);
  return (
    <div className={formCss.contact_form + " bg-[#000] py-24"}>
      <Title
        title="Let's help you!"
        subTitle="We’d love to build something great together."
      />
      <Container>
        <div className={`${px768 ? "block" : "grid"} grid-cols-2 gap-10`}>
          <div
            // onClick={handleSubmit}
            className={`pb-4 px-5 pt-3 ${
              px500 ? "w-full" : px768 ? "w-2/3" : !px1024 && "w-2/3"
            }`}
          >
            <h1 className="text-2xl" style={{ color: "#008eff" }}>
              Get In Touch
            </h1>
            <a href="/" className="flex items-center gap-2 mt-3">
              <div
                className="p-2 rounded-full border"
                style={{ borderColor: "#008eff", color: "#008eff" }}
              >
                <Phone />
              </div>
              <p className="text-white">+8809611223757</p>
            </a>
            <a href="/" className="flex items-center gap-2 mt-3">
              <div
                className="p-2 rounded-full border"
                style={{ borderColor: "#008eff", color: "#008eff" }}
              >
                <Mail />
              </div>
              <p className="text-white">query@pixfar.com</p>
            </a>
            <a href="/" className="flex items-center gap-2 mt-3">
              <div
                className="p-2 rounded-full border"
                style={{ borderColor: "#008eff", color: "#008eff" }}
              >
                <MdPlace size={25} />
              </div>
              <p className="text-white">
                House #12, Road-08, Block-D, Mirpur-11, Dhaka 1216, Bangladesh.
              </p>
            </a>
            <div className="mt-5">
              <SocialIcon />
            </div>
            <hr className="my-5" />
            <div>
              <a
                href="https://calendly.com/pixfar-quick-meeting/30min"
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a Meeting
              </a>
            </div>
          </div>
          <div className={formCss.contact_info}>
            <img
              src="https://adoctor.in/wp-content/uploads/2020/09/contact-1024x763.png"
              alt=""
              style={{
                width: px1024 ? "70%" : "60%",
                margin: px768 && "auto",
                marginTop: px768 && "20px",
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
