/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import aboutUsBg from '../../Assets/videos/aboutUS.mp4';
import BannerElement from '../../Components/Banner/BannerElement/BannerElement';
import BestForAbout from '../../Components/BestForAbout/BestForAbout';
import Button from '../../Components/Button/Button';
import Container from '../../Components/Container/Container';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import AboutCSS from './About.module.css';
const bannerBg =
  "https://res.cloudinary.com/pixeldust-tech/video/upload/v1713437170/pixeldust-website/itqqo4bz4ibkwzkrxptt.mp4";

const About = () => (
  <div className={AboutCSS.about_page}>
    {/* <div className="">
            <PageTitle
                title="About Pixfar"
                // subTitle="Advancing the Frontier of Innovation At Pixfar Technologies, we redefine the landscape of technological advancement, offering unparalleled expertise in navigating the intricacies of modern tech. Our suite of cutting-edge solutions empowers organizations to harness the full potential of their digital transformations."
                current="About"
                videoSrc={aboutUsBg}
            />
        </div> */}

    <BannerElement video={aboutUsBg}>
      <Container>
        <h1 className="font-[800] text-[50px] text-white" style={{ lineHeight: 1.2 }}>
          Advancing the Frontier <br /> of Innovation
        </h1>
        <p className="text-white w-[50%] mt-5">
          At Pixfar Technologies, we redefine the landscape of technological
          advancement, offering unparalleled expertise in navigating the
          intricacies of modern tech. Our suite of cutting-edge solutions
          empowers organizations to harness the full potential of their digital
          transformations.
        </p>
      </Container>
    </BannerElement>

    <ScrollToTop />

    <div className="bg-black">
      <Container>
        <div
          className={`grid grid-cols-2  items-center py-32 ${AboutCSS.short_disc}`}
        >
          <img
            src="https://i.ibb.co/HxgpX2y/pexels-kevin-ku-577585.jpg"
            alt=""
          />
          <div>
            <h1>A Short Introduction</h1>
            <p className="text-gray-300 text-[16px]">
              <strong className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                Pixfar
              </strong>{" "}
              is a software company that specialize in delivering advanced software solutions designed to help businesses streamline their operations, improve efficiency, and make smarter decisions. Our comprehensive suite of services includes Enterprise Resource Planning (ERP), Point of Sale (POS), Big Data Analytics, AI Retrieval-Augmented Generation (RAG) Solutions, and Custom Application Development. Each solution is tailored to meet the unique needs of businesses, enabling them to optimize workflows, enhance productivity, and gain valuable insights from data.
            </p>
            <p className="text-gray-300 text-[16px]">
              <strong className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                At Pixfar
              </strong>
              , we focus on innovation and excellence, we empower businesses of all sizes to stay agile and competitive in today's fast-paced digital landscape. Our team of dedicated professionals combines industry expertise with cutting-edge technology to deliver scalable, user-friendly solutions that drive measurable results. Whether you're looking to streamline financial processes, improve customer interactions, leverage big data for strategic decisions, or build custom software applications, Pixfar is your trusted partner in achieving long-term success..
            </p>
            <Button id="offcanvas" text="Book a Meeting" />
          </div>
        </div>
      </Container>
    </div>

    <BestForAbout />
    {/* <Newslatter /> */}
  </div>
);

export default About;
