import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BackToTop from "./Components/BackToTop/BackToTop";
import BlogSingle from "./Components/BlogSection/BlogSingle/BlogSingle";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import OffCanvas from "./Components/OffCanvas/OffCanvas";
import About from "./Pages/About/About";
import Blog from "./Pages/Blog/Blog";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import NotFound from "./Pages/NotFound/NotFound";
import Portfolio from "./Pages/Portfolio/Portfolio";
import PortfolioSingle from "./Pages/Portfolio/PortfolioSingle/PortfolioSingle";
import Service from "./Pages/Service/Service";
import AI from "./Pages/Service/ServiceSinglePage/AI/AI";
import DataEng from "./Pages/Service/ServiceSinglePage/DataEng/DataEng";
import Erp from "./Pages/Service/ServiceSinglePage/Erp/Erp";
import Lms from "./Pages/Service/ServiceSinglePage/Lms/Lms";
import Remote from "./Pages/Service/ServiceSinglePage/Remote/Remote";
import Website from "./Pages/Service/ServiceSinglePage/Website/Website";
import Products from "./Pages/products/Products";
import ProductSingle from "./Pages/products/productSingle/ProductSingle";
import WhyChoose from "./Pages/why-choose/WhyChoose";

function App() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrollToTop(true);
      } else {
        setScrollToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <BackToTop />
      <OffCanvas />
      {scrollToTop && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        {/* <Route path="/testimonial" element={<Review />} /> */}
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:id" element={<PortfolioSingle />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:name" element={<ProductSingle />} />
        <Route path="/why-choose-us" element={<WhyChoose />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogSingle />} />
        <Route path="/services" element={<Service />} />
        {/* <Route path="/service/:name" element={<ServiceSinglePage />} /> */}
        {/* <Route path="/services/:name" element={<ServiceSinglePage />} /> */}
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/service/ai" element={<AI />} />
        <Route path="/service/erp" element={<Erp />} />
        <Route path="/service/lms" element={<Lms />} />
        <Route path="/service/data-engineering" element={<DataEng />} />
        <Route path="/service/remote-desktop" element={<Remote />} />
        <Route path="/service/web-development" element={<Website />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
