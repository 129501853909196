import Header from "../../Components/Header/Header";

export default function NotFound() {
  return (
    <div>
      <Header />
      <div className="flex h-screen items-center justify-center bg-gray-900 text-white">
        <div className="text-center p-8 rounded-2xl shadow-xl bg-gray-800 border border-gray-700 animate-fade-in">
          <h1 className="text-9xl font-bold mb-4">404</h1>
          <h2 className="text-3xl font-semibold mb-6">Oops! Page not found</h2>
          <p className="mb-6 text-gray-400">
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </p>
          <a
            href="/"
            className="btn"
          >
            Go Back Home
          </a>
        </div>
      </div>
    </div>
  );
}
