import React from 'react';
import { useParams } from 'react-router-dom';
import video from '../../../Assets/videos/webdevelopment.mp4'; // Import the background video
import BannerElement from '../../../Components/Banner/BannerElement/BannerElement'; // Import BannerElement
import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import PortfolioImageSlider from '../../../Components/PortfolioSection/ImageSlider/PortfolioImageSlider';
import portfolioData from '../../../Components/PortfolioSection/PortfolioBox/protfolioData';
import ScrollToTop from '../../../Components/ScrollToTop/ScrollToTop';

const PortfolioSingle = () => {
    const { id } = useParams();
    const portfolio = portfolioData?.filter((data) => data?.id === Number(id))[0];

    return (
        <div className="portfolio_single"> {/* 300px margin-top on full page */}
            {/* Banner Element with video background */}
            <BannerElement video={video}>
                <ScrollToTop />
                <Container style={{ marginTop: '50px' }}> {/* Added margin-top to content */}
                    {/* <div className="portfolio_title border border-slate-600 rounded-lg p-2">
                        <h1 className="text-3xl mb-2 font-semibold mb-2 text-white text-center">{portfolio.title}</h1>
                        <Breadcrumbs
                            link1={{ name: 'Portfolio', link: '/portfolio' }}
                            current="Portfolio Item"
                        />
                    </div> */}

                    <div className="grid grid-cols-3 gap-5 mt-10">
                        <PortfolioImageSlider data={portfolio?.image} />
                        {portfolio?.image?.map((image) => (
                            <label htmlFor="portfolioImageSlider" style={{ cursor: 'zoom-in' }} key={image.img}>
                                <img
                                    src={image.img}
                                    alt=""
                                    className="w-full rounded-lg"
                                    data-aos="fade-up"
                                />
                            </label>
                        ))}
                    </div>

                    <div className="mt-10 text-white mb-5">
                        <p className="text-white">{portfolio?.desc}</p>
                    </div>

                    <Button id="offcanvas" text="Book a Meeting" className="mt-10" />
                </Container>
            </BannerElement>
        </div>
    );
};

export default PortfolioSingle;
