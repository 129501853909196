// * Import for This Component
import { useState } from "react";
import { TiMinus, TiPlus } from "react-icons/ti";
import { Link } from "react-router-dom";

// * Accordion Box
const Accordion = ({ item, noBtn }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mb-5 bg-[#191919]">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full border py-4 px-6 border-gray-800 text-white hover:bg-slate-600"
      >
        {item.title} {isOpen ? <TiMinus /> : <TiPlus />}
      </button>

      {isOpen && (
        <div className="w-full border border-t-0 border-gray-800">
          <img src={item.image} alt="" />

          <div className="py-4 px-6">
            <p className="text-white text-[15px]">{item.desc}</p>

          {!noBtn &&  <Link
              to={"/service" + item.link}
              className="bg-slate-600 py-3 text-[14px] px-5 mt-5 inline-block rounded-full text-white"
            >
              Learn More
            </Link>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;