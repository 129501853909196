/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import { ChevronDown, ChevronUp, X } from 'react-feather';
import logo from '../../../../Assets/Images/logo.png';
import sectionData from '../../../../Pages/Service/ServiceData/sectionData';
import Email from '../../../ContactInfo/Email';
import Number from '../../../ContactInfo/Number';
import SocialIcon from '../../../SocialIcon/SocialIcon';
import MenuList from './MenuList';
import MobileMenuCss from './MobileMenuOffcanvas.module.css';

const MobileMenuOffcanvas = ({ state, setState }) => {
    const ref = useRef();
    return (
      <div
        className={MobileMenuCss.menu}
        style={state ? { left: "0" } : { left: "-700px" }}
      >
        <div className={MobileMenuCss.header}>
          <div className={MobileMenuCss.logo}>
            <img src={logo} alt="" style={{ width: "50px" }} />
            <h1 className="text-white">ixfar</h1>
          </div>
          <X
            size={30}
            className="cursor-pointer"
            onClick={() => setState(false)}
          />
        </div>
        <div className="mt-5 overflow-x-auto h-[82vh]">
          <ul className={MobileMenuCss.menu_item}>
            <MenuList title="Home" link="/" setState={setState} />
            <MenuList title="About" link="/about-us" setState={setState} />
            <li>
              <details>
                <summary ref={ref} className="flex justify-between">
                  Solutions
                  <div className={MobileMenuCss.down_icon}>
                    <ChevronDown size={20} />
                  </div>
                  <div className={MobileMenuCss.up_icon}>
                    <ChevronUp size={20} />
                  </div>
                </summary>
                <ul>
                  {sectionData.map((item, index) => (
                    <MenuList
                        key={index}
                      title={item.title}
                      link={"/service" + item.link}
                      setState={setState}
                    />
                  ))}
                </ul>
              </details>
            </li>
            {/* <MenuList
              title="Why Choose Us"
              link="/why-choose-us"
              setState={setState}
            />
            <MenuList title="Portfolio" link="/portfolio" setState={setState} />
            <MenuList title="Blog" link="/blog" setState={setState} />
            <MenuList
              title="Contact Us"
              link="/contact-us"
              setState={setState}
            /> */}
          </ul>
          <div className="mt-10">
            <SocialIcon />
          </div>
          <div className="mt-5">
            <Number />
          </div>
          <div className="mt-2">
            <Email />
          </div>
        </div>
      </div>
    );
};

export default MobileMenuOffcanvas;
