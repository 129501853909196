import React from 'react';
import video from '../../Assets/videos/webdevelopment.mp4';
import BannerElement from '../../Components/Banner/BannerElement/BannerElement';
import Container from '../../Components/Container/Container';
import PortfolioSection from '../../Components/PortfolioSection/PortfolioSection';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import Testimonial from '../../Components/Testimonial_Section/Testimonial';

const Portfolio = () => (
  <div className="portfolio_page">
    <ScrollToTop />
    <BannerElement video={video}>
      <Container>
        <div className="text-center">
          <div className="text-center text-[35px] font-bold text-white">Portfolio</div>
          {/* <p className="w-[50%] mx-auto text-white text-center text-[15px]">
            {data?.banner.subtitle}
          </p> */}
        </div>
      </Container>
    </BannerElement>
    <PortfolioSection type="page" />
    <Testimonial />
  </div>
);

export default Portfolio;
