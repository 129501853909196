import React from 'react';
import Ratting from '../Ratting/Ratting';

const TestimonialSingel = ({ name, img, msg, rtn, time, className }) => {
    const [showDescription, setShowDescription] = React.useState(false);

    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    const descrition = () => {
        if (showDescription) {
            return (
                <td className={` px-2`}>
                    {msg}
                    <button
                        className="border-0 bg-transparent orange-500"
                        onClick={handleShowDescription}
                        type="button"
                    >
                        ....
                    </button>
                </td>
            );
        }
        if (msg?.length < 110) {
            return <td className={` px-2`}>{msg}</td>;
        }
        return (
            <td className={` px-2`}>
                {msg?.substring(0, 180)}
                <button
                    className="border-0 bg-transparent"
                    onClick={handleShowDescription}
                    type="button"
                >
                    ....
                </button>
            </td>
        );
    };

    return (
        <div className={`testimonial_box ${className}`}>
            <div className="testimonial_content">
                <p className="text-white text-[14px]">{descrition()}</p>
            </div>
            <div className="testimonial_auth">
                <div className="testimonial_auth_left">
                    <img src={img} alt="" />
                    <div className="testimonial_auth_name">
                        <h3>{name}</h3>
                        <p className="text-[#ffffff4f] !text-[12px]">Client</p>
                    </div>
                </div>
                <div className="testimonial_auth_right">
                    <Ratting rtn={rtn} />
                    <span>{time}</span>
                </div>
            </div> 
        </div>
    );
};

export default TestimonialSingel;
