import React, { useState } from "react";
import video from "../../Assets/videos/techvieo.mp4";
import useWindowWidth from "../../Utils/WindowCaptcher";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import BestList from "./BestList/BestList";

const bestItems = [
  {
    text: "Enterprise Resource Planning (ERP)",
    description:
      "Learn how our ERP solutions can streamline your business operations.",
    color: "#008eff40",
  },
  {
    text: "Learning Management System (LMS)",
    description:
      "Discover our LMS that empowers organizations to deliver engaging learning experiences.",
    color: "#011627",
  },
  {
    text: "Data Engineering",
    description:
      "Explore our data engineering services for scalable and efficient data solutions.",
    color: "#413020",
  },
  {
    text: "Remote Desktop Solution (RDP)",
    description:
      "Find out how our RDP services can provide seamless remote connectivity.",
    color: "#60dbfa",
  },
  {
    text: "Artificial Intelligence (AI)",
    description:
      "See how our AI solutions help drive smarter business decisions.",
    color: "#214ce5",
  },
  {
    text: "Mobile App Development",
    description:
      "Learn about our mobile app development expertise to bring your ideas to life.",
    color: "#ce679a",
  },
  {
    text: "Software Consulting",
    description:
      "Benefit from our software consulting services to optimize your IT strategy.",
    color: "#011627",
  },
];

const BestForAbout = () => {
  const px1024 = useWindowWidth(1024);
  const [selectedItem, setSelectedItem] = useState(bestItems[0]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div
      className="py-32"
      style={{
        backgroundImage: 'url("https://i.ibb.co/jrB5STZ/download-1.png")',
        backgroundPosition: "bottom",
        backgroundRepeat: "repeat",
        backgroundColor: "black",
      }}
    >
      <Container>
        <div className="flex justify-center pb-5">
          <Heading middle="What We Do Best" />
        </div>
        <div
          className={`${
            px1024 ? "block" : "grid"
          } grid-cols-2 gap-10 items-center`}
        >
          <div className="list">
            <ul>
              {bestItems.map((item, index) => (
                <li key={index} onClick={() => handleItemClick(item)}>
                  <BestList text={item.text} color={item.color} />
                </li>
              ))}
            </ul>
          </div>

          <div className="relative h-full overflow-hidden rounded-lg flex items-center">
            <video
              autoPlay
              loop
              muted
              className="absolute inset-0 w-full h-full object-cover z-0"
            >
              <source src={video} type="video/mp4" />
            </video>
            <div className="relative z-10 p-8 rounded-lg space-y-3">
              {selectedItem ? (
                <>
                  <h3 className="text-4xl text-cyan-500 font-bold">
                    {selectedItem.text}
                  </h3>
                  <p className="text-white">{selectedItem.description}</p>
                  <a
                    href="https://calendly.com/pixfar-quick-meeting/30min"
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book a Meeting
                  </a>
                </>
              ) : (
                <p className="text-white">Select an option to see details</p>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BestForAbout;
