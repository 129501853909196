/* eslint-disable no-nested-ternary */
import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaDeaf, FaUserTie } from 'react-icons/fa';
import { MdHomeRepairService, MdSupportAgent } from 'react-icons/md';
import { SiMaterialdesignicons } from 'react-icons/si';
import Button from '../../Components/Button/Button';
import Container from '../../Components/Container/Container';
import PageTitle from '../../Components/PageTitle/PageTitle';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import useWindowWidth from '../../Utils/WindowCaptcher';
import WhyChooseBox from './whyChooseBox/WhyChooseBox';

const WhyChoose = () => {
    const px1280 = useWindowWidth(1280);
    const px1024 = useWindowWidth(1024);
    const px620 = useWindowWidth(620);
    return (
        <>
            <PageTitle current="Why Choose Us" title="Why Choose Us" />
            <ScrollToTop />
            <div style={{ backgroundColor: '#f5f8ff' }} className="py-20">
                <Container>
                    <div
                        className={`grid ${
                            px620
                                ? 'grid-cols-1'
                                : px1024
                                ? 'grid-cols-2'
                                : px1280
                                ? 'grid-cols-2'
                                : 'grid-cols-3'
                        } gap-10`}
                    >
                        <WhyChooseBox
                            title="Expertise and Experience"
                            desc="With years of experience in the software development industry, Pixfar boasts a team of highly skilled professionals who excel in their respective domains. Our expertise spans across a wide range of technologies and industries, enabling us to deliver innovative and tailored solutions to meet your unique business needs."
                            icon={<SiMaterialdesignicons size={40} />}
                        />
                        <WhyChooseBox
                            title="Customized Solutions"
                            desc="We understand that every business is unique, and we believe in providing customized solutions that align with your specific requirements. Our team works closely with you to gain a deep understanding of your goals and challenges, ensuring that the solutions we deliver are perfectly tailored to address your business needs."
                            icon={<FaDeaf size={40} />}
                        />
                        <WhyChooseBox
                            title="Quality and Reliability"
                            desc="At Pixfar, we prioritize quality and reliability in everything we do. We follow industry best practices and rigorous quality assurance processes to ensure that our software solutions are of the highest standards. We strive to deliver reliable, robust, and scalable solutions that you can trust for your critical business operations."
                            icon={<MdHomeRepairService size={40} />}
                        />
                        <WhyChooseBox
                            title="Client-Centric Approach"
                            desc="We value our clients and prioritize their success above all else. Our client-centric approach means that we actively listen to your needs, provide transparent communication, and keep you involved throughout the project lifecycle. We believe in building strong and lasting relationships with our clients based on trust, collaboration, and mutual success."
                            icon={<FaUserTie size={40} />}
                        />
                        <WhyChooseBox
                            title="Timely Delivery"
                            desc="We understand the importance of timely project delivery. Our team is committed to meeting deadlines and ensuring that your projects are completed within the agreed-upon timeframe. We prioritize efficient project management and effective communication to ensure a smooth and timely delivery process."
                            icon={<AiOutlineClockCircle size={40} />}
                        />
                        <WhyChooseBox
                            ttle="Ongoing Support and Maintenance"
                            desc="Our relationship with clients extends beyond project completion. We offer comprehensive support and maintenance services to ensure that your software solutions continue to perform optimally. Whether it's resolving issues, applying updates, or providing technical assistance, we are there to support you every step of the way."
                            icon={<MdSupportAgent size={40} />}
                        />
                    </div>
                    <div className="mt-10 text-center">
                        <Button id="offcanvas" text="Book a Meeting" />
                    </div>
                    <div className="mt-10 text-center w-3/5 m-auto">
                        <p>
                            Choose Pixfar as your software development partner, and experience the
                            benefits of our expertise, customized solutions, quality assurance,
                            client-centric approach, timely delivery, and ongoing support. We are
                            committed to helping you achieve your business objectives and driving
                            your success in the digital landscape.
                        </p>
                    </div>
                </Container>
            </div>
            {/* <Newslatter /> */}
        </>
    );
};

export default WhyChoose;
