/* eslint-disable no-underscore-dangle */

import React from 'react';
import { SwiperSlide } from 'swiper/react';
import Container from '../Container/Container';
import Heading from '../Heading/Heading';
import SwiperSlider from '../SwiperSlide/SwiperSlide';
import './Testimonial.scss';
import TestimonialSingel from './TestimonialSingel';

const Testimonial = () => (
  <div className="testimonial_section">
    <Container>
      <div className="flex justify-center pb-5">
        <Heading first="What" middle="Clients" middleRight="Say" />
      </div>
      <div className="testimonial_section_body">
        <SwiperSlider type="testimonial" speed={3000}>
          <SwiperSlide>
            <TestimonialSingel
              name="Lumini Taeric"
              img="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/66123500980899842b29449e54be8cfe-1363446031683213206131/JPEG_20230504_161325_5929622769369924299.jpg"
              msg="Pixfar is a great professional, who has many qualities, the main one for us is listening to his client. We had a perfect collaboration and we thank him very much for his quality work. We will not hesitate to come back to him for other work. Thank you again dear Pixfar and looking forward to seeing you"
              rtn="5"
              time=""
              key="data._id"
            />
          </SwiperSlide>

          <SwiperSlide>
            <TestimonialSingel
              name="Tamjidul Hassan"
              img="https://i.ibb.co/CHq5VvB/image.png"
              msg="We needed a modern, optimized and user-friendly website to operate our learning Institution. We are very satisfied with their work. We would like to work with them again"
              rtn="5"
              time=""
              key="data._id"
            />
          </SwiperSlide>

          <SwiperSlide>
            <TestimonialSingel
              name="Hammamre Treat"
              img="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/90d6ba79339760570d5518ab103130f5-1664523013657/655917fd-75f3-4595-90db-8c8b6fd68a51.jpeg"
              msg="If you are looking for a kind, honest and professional designer, then choose Pixfar. I have never worked with someone who knew what I wanted from the beginning; he sent me the first template and that was it!! My website looks great, and I am so excited to have chosen him. I am going to recommend him to all my friends who are looking to create websites for their businesses."
              rtn="5"
              time=""
              key="data._id"
            />
          </SwiperSlide>

          {/* <SwiperSlide>
                            <TestimonialSingel
                                name="data.name"
                                img={avatar}
                                msg="The expertise and dedication of the Pixfar team have been instrumental in our success. They not only developed a robust big data solution for us but also provided comprehensive insights that have transformed our business strategy. Their professionalism, technical proficiency, and exceptional support have made them an invaluable partner. We confidently recommend Pixfar to anyone seeking innovative data-driven solutions."
                                rtn="5"
                                time="1 day ago"
                                key="data._id"
                            />
                        </SwiperSlide> */}
        </SwiperSlider>
      </div>
      {/* <div className="text-center">
                    <Button text="View All" className="mt-16 rounded-xl" link="/testimonial" anim />
                </div> */}
    </Container>
  </div>
);

export default Testimonial;
