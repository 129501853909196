import React from "react";
import { SwiperSlide } from "swiper/react";
import SwiperSlider from "../../SwiperSlide/SwiperSlide";
import PortfolioBox from "../PortfolioBox/PortfolioBox";

const PortfolioMobileSlider = ({ data }) => (
  <SwiperSlider type="portfolio" speed={2000}>
    {data.length > 0 &&
      data.map((portfolio) => (
        <SwiperSlide key={portfolio.id}>
          <PortfolioBox
            image={portfolio.thumb}
            name={portfolio.title}
            ratting="1"
            link={portfolio.id}
          />
        </SwiperSlide>
      ))}
  </SwiperSlider>
);

export default PortfolioMobileSlider;
