import React from "react";
import Marquee from "react-simple-marquee";
import { TypeAnimation } from "react-type-animation";
import bannerBg from "../../Assets/videos/bannerbg.mp4";
import BannerElement from "../../Components/Banner/BannerElement/BannerElement";
import bannerCss from "../../Components/Banner/BannerElement/BannerElement.module.css";
import Button from "../../Components/Button/Button";
import Container from "../../Components/Container/Container";
import Heading from "../../Components/Heading/Heading";
import LogoBox from "../../Components/LogoBox/LogoBox";
import PortfolioSection from "../../Components/PortfolioSection/PortfolioSection";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop";
import ServiceSection from "../../Components/ServiceSection/ServiceSection";
import ServiceBox from "../../Components/ServiceSection/Services/BannerServiceBox/BannerServiceBox";
import SocialIcon from "../../Components/SocialIcon/SocialIcon";
import Testimonial from "../../Components/Testimonial_Section/Testimonial";
import useWindowWidth from "../../Utils/WindowCaptcher";
import sectionData from "../Service/ServiceData/sectionData";

const Home = () => {
  const px1024 = useWindowWidth(1024);
  return (
    <div className="home">
      <ScrollToTop />
      <BannerElement video={bannerBg}>
        <Container>
          <div className={`${bannerCss.banner_content}flex items-center`}>
            <h2 className="text-[18px] inline-block md:text-[2em] text-white md:text-white font-extrabold ">
              <span className="text-[24px] md:text-4xl !-mb-[10px]">
                {" "}
                Your Business
              </span>{" "}
              <br /> Problems, Solved With
            </h2>{" "}
            <TypeAnimation
              sequence={["AI", 1000, "Tech.", 1000, "Design.", 1000]}
              wrapper="span"
              speed={50}
              cursor={false}
              className="text-[18px] md:text-[2em] text-[#008EFF] font-extrabold"
              repeat={Infinity}
            />
            <h2 className="text-[17px] text-gray-200 my-5">
              Utilize the power of Tech + Design + AI to get customized
              solutions for your business.
            </h2>
            {/* <p>{desc || ""}</p> */}
            <div>
              <a
                href="https://calendly.com/pixfar-quick-meeting/30min"
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a Meeting
              </a>
            </div>
            <div className="mt-5">
              <SocialIcon />
            </div>
          </div>

          <div className="mt-10 hidden md:grid grid-cols-4 xl:grid-cols-7">
            {sectionData.map((item, index) => (
              <div key={index}>
                <ServiceBox
                  item={item}
                  isLast={sectionData?.length === index + 1}
                />
              </div>
            ))}
          </div>
        </Container>
      </BannerElement>

      <ServiceSection />

      <div
        style={{
          backgroundImage:
            "url(https://i.ibb.co/5KDSC4j/White-Dots-D4-ib2-Mf.png)",
          backgroundRepeat: "repeat",
          backgroundSize: "70%",
          backgroundAttachment: "fixed",
          backgroundColor: "#000",
        }}
        className="py-24"
      >
        <Container>
          <h1 className="text-center text-white mb-[16px] text-4xl font-medium">
            Revolutionize{" "}
            <span className="bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
              Your Business
            </span>{" "}
            with <br /> Pixfar for Success
          </h1>
          {/* <Title title="Revolutionize Your Business  with <br/> Pixfar for Success" /> */}
          <div
            className={`${
              px1024 ? "block" : "grid"
            } grid-cols-2 gap-20 items-center`}
          >
            {px1024 && (
              <img
                src="https://st.depositphotos.com/1720162/1817/i/450/depositphotos_18177429-stock-photo-web-design-concept.jpg"
                style={{ width: "100%" }}
                alt=""
                className="rounded-xl"
              />
            )}
            <div className="mt-5">
              <h1
                className="text-3xl"
                style={{ color: "#008eff", fontWeight: "600" }}
              >
                Who is Pixfar?
              </h1>
              <p className="my-5 text-[18px] text-white">
              Pixfar, we specialize in delivering innovative software solutions that empower businesses to streamline their operations, enhance efficiency, and make data-driven decisions. Our comprehensive suite of services includes Enterprise Resource Planning (ERP), Point of Sale (POS), Big Data Analytics, AI RAG Solutions, and Custom Application Development, tailored to meet the diverse needs of businesses of all sizes.
              </p>
              <Button id="offcanvas" text="Book a Meeting" />
            </div>
            {!px1024 && (
              <img
                src="https://i.ibb.co/LhJkBXz/pexels-fauxels-3182773.jpg"
                style={{ width: "100%" }}
                alt=""
                className="rounded-xl animate-pulse delay-100 "
              />
            )}
          </div>
        </Container>
      </div>

      {/* Happy client */}
      <div className="w-full p-32 bg-[#191919]">
        <Container>
          <div className="flex justify-center pb-5">
            <Heading first="Our" middle="Success" last="With this brand" />
          </div>

          <Marquee speed={3}>
            <LogoBox image="https://i.ibb.co/ys46tv5/ic-launcher-round.png" />
            <LogoBox image="https://res.cloudinary.com/dydfwvvro/image/upload/v1740590844/pdbi9gokrr9jc9wxxdnn.svg" />
            <LogoBox image="https://i.ibb.co/fMz0c1s/Vector.png" />
            <LogoBox image="https://res.cloudinary.com/dydfwvvro/image/upload/v1740590914/rc1gzkyb7izfggdq3xfi.svg" />
            <LogoBox image="https://res.cloudinary.com/dydfwvvro/image/upload/v1740590966/xrjs5kqg7hqdyn7woqae.webp" />
            <LogoBox image="https://res.cloudinary.com/dydfwvvro/image/upload/v1740590999/fbhajswz2o32kthf1ztp.png" />
            <LogoBox image="https://i.ibb.co/CwdPPks/default-monochrome-white-1.png" />
          </Marquee>
        </Container>
      </div>
      {/* <ServiceSection /> */}
      <PortfolioSection />
      {/* <FrameWork /> */}
      <Testimonial />
      {/* <Newslatter /> */}
      {/* <LogoSlider /> */}
      {/* <ContactForm /> */}
    </div>
  );
};

export default Home;
