/* eslint-disable no-nested-ternary */
import React from "react";
import serviceBannerImage from "../../Assets/Images/Service-banner-image.jpg";
import FrameWork from "../../Components/FrameWork/FrameWork";
import Header from "../../Components/Header/Header";
import LogoSlider from "../../Components/LogoSlider/LogoSlider";
import Newslatter from "../../Components/Newslatter/Newslatter";
import PageTitle from "../../Components/PageTitle/PageTitle";
import ServiceSection from "../../Components/ServiceSection/ServiceSection";
import useWindowWidth from "../../Utils/WindowCaptcher";

const Service = () => {
  const px1280 = useWindowWidth(1280);
  const px1024 = useWindowWidth(1024);
  const px450 = useWindowWidth(500);
  return (
    <>
      <Header />
      <PageTitle img={serviceBannerImage} title="Services" current="Services" />
      {/* <ServiceSection /> */}
      <div className="">
        {/* <Title title="Our Services" /> */}
        <ServiceSection />
      </div>
      <FrameWork />
      <Newslatter />
      <LogoSlider />
    </>
  );
};

export default Service;
