import { Link } from 'react-router-dom';

const ServiceBody = ({ title, bodyData, image, isButton, url }) => {
  return (
    <>
      <div className="flex justify-between">
        <img
          className="h-[500px] w-[45%] object-cover"
          src={bodyData.bodyImg}
          alt=""
        />
        <div className="w-full customBg p-16">
          <h2 className="text-[30px] font-semibold text-white">{bodyData.title}</h2>
          <p className="text-white mt-3 text-[15px] mb-5">{bodyData?.desc}</p>
          {isButton && (
            <Link
              to={'/service'+bodyData.link}
              className="border py-3 px-7 text-[15px] border-white hover:bg-[#003e706c] text-white rounded-full"
            >
              Learn More
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
 
export default ServiceBody;