import React from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';

const FooterList = ({ text, link }) =>
    text && (
        <li className="text-gray-500 footer_hover text-base flex items-center gap-2 mb-1">
            <ArrowRight size={15} />
            <Link to={link || '/'} className="text-[15px] text-white">{text}</Link>
        </li>
    );

export default FooterList;
